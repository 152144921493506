import React, { useEffect, Fragment } from "react"
import { Img } from "react-image";
import ImageDarkTextRightWithButton from "./components/imageDarkTextRightWithButton"
import ImageDarkTextLeftWithButton from "./components/imageDarkTextLeftWithButton"
import CTABusiness from "../../general/ctaBusiness"
import FAQList from '../../general/faqsList';
import InnerPageHeader from "./components/innerPageHeader";
import { scrollToElement } from "../../../../utility/utils"
import DarkBelowFoldCards from "../../general/darkBelowFoldCards";
import MoreForYouComponent from "../../general/more-for-you";
import { moreForYou, moreForYou1 } from "./features";

import EntryIllustration from '../../../../../assets/img/payroll-bento-hero-image.svg'
import EntryBlurIllustration from '../../../../../assets/img/business/blur/payroll-bento-hero-blur.svg'
import FlatFeeIcon from "../../../../../assets/img/kuda-flat-fee.inline.svg"
import WarningIcon from "../../../../../assets/img/kuda-warning.inline.svg"
import SendPurpleIcon from '../../../../../assets/img/kuda-send-purple.inline.svg'

import automationIllustration from "../../../../../assets/img/business/payroll/automation-illustration.svg"
import paydayIllustration from "../../../../../assets/img/business/payroll/payday-illustration.svg"
import manualCalcIllustration from "../../../../../assets/img/business/payroll/maunalCal-illustration.svg"

const entryContent = {
  title: (<span>
    Take the stress out of payroll <span className="color-secondary">with Bento</span> on Kuda Business.
  </span>),
  subtitle: "Automate salary payments, pension, tax and salary advance deductions for any number of employees.",
  name: "Get Kuda Business",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
}

const businessTopFeatures = [
  {
    icon: <FlatFeeIcon />,
    text: "Automate your payroll to pay salaries on time every time."
  },
  {
    icon: <WarningIcon />,
    text: "Avoid payment errors with easy employee profile updates."
  },
  {
    icon:  <SendPurpleIcon />,
    text: "Simplify one-off and recurring salary deductions."
  },
]

const payrollAutomation = {
  title: "Save time with powerful payroll automation.",
  subtitle: "Bento on Kuda Business makes it easy to add all your employees to your payroll at once and pay them at once.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Download Kuda Business",
  illustration: (
    <Img src={automationIllustration} className="kuda-business-image" />

  ),
}

const payrollPay = {
  title: "Never pay the wrong person again.",
  subtitle: "View, edit and delete employee details any time to keep your payroll up to date before payday.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Download Kuda Business",
  illustration: (

    <Img src={paydayIllustration} className="kuda-business-image" />

  ),
}

const payrollCalculation = {
  title: "Skip manual calculations forever.",
  subtitle: "Automate one-off and recurring deductions like pension, tax and salary advance repayments.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Get Kuda Business",
  illustration: (

    <Img src={manualCalcIllustration} className="kuda-business-image" />

  ),
}


const questions = [
  {
    heading: "What is payroll?",
    list: (
      <span className="flex flex-column  mt-2">

        <span className="faq-description f-16">Payroll is the process of paying your employees. It’s also a list of your employees, their salaries, taxes and other deductions as well as your business’ contributions and taxes.</span>

        {/* <span className="faq-description f-16">You need invoicing for the following reasons:</span><br />


       */}

      </span>),
    index: 1
  },
  {
    heading: "What is Bento?",
    list: (
      <span className="flex flex-column  mt-2">
        <span className="faq-description f-16">Bento is a payroll automation and HRM (human resources management) app. It simplifies payroll and HRM to save you time and keep your employees happy with timely salary payments.
        </span> <br /> <br />

        <span className="faq-description f-16">
          We’ve partnered with Bento so that you can pay your employees from your Kuda Business account without manual calculations or payment errors.
        </span>
      </span>),
    index: 2
  },
  {
    heading: "Who can use Payroll With Bento on Kuda Business?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">
          Anyone with a full Kuda Business account can use Bento on Kuda Business. To upgrade your Kuda Business account, just add your CAC registration documents.
        </span>
      </div>),
    index: 3
  },
  {
    heading: "How do I use Payroll With Bento on Kuda Business?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">
          To use Bento on Kuda Business, follow the steps below:
        </span>

        <span className="pt-3 career-message-margin-bottom ">
          <div className="flex flex-column  leading-md-6 f-md-16">
            <div className="mb-3">1. Sign in to Kuda Business <a href="https://kudabusiness.onelink.me/epwz/jceed731/" target="_blank" rel="noopener noreferrer"  className="cursor-pointer text-bold color-primary text-underline">here.</a></div>
            <div className="mb-3">2. Tap <b>Tools</b> on the left side of your screen.</div>
            <div className="mb-3"> 3. Tap <b>Payroll With Bento.</b></div>
            <div className="mb-3">4.Tap <b>Get Started,</b> then follow the prompts on your screen.</div>
          </div>
        </span>

      </div>),
    index: 4
  },
]

const PayrollBento = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (

    <Fragment>

      <InnerPageHeader
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        buttonName={entryContent.name}
        buttonUrl={entryContent.url}
        entryIllustration={EntryIllustration}
        fallback={<Img src={EntryBlurIllustration} className="hero-illustration" />}
      />
      <DarkBelowFoldCards topFeatures={businessTopFeatures} />
      <ImageDarkTextRightWithButton
        title={payrollAutomation.title}
        subtitle={payrollAutomation.subtitle}
        illustration={payrollAutomation.illustration}
        name={payrollAutomation.name}
        url={payrollAutomation.url}
        isExternal={true}
      />
      <ImageDarkTextLeftWithButton
        title={payrollPay.title}
        subtitle={payrollPay.subtitle}
        illustration={payrollPay.illustration}
        name={payrollPay.name}
        url={payrollPay.url}
        isExternal={true}
      />
      <ImageDarkTextRightWithButton
        title={payrollCalculation.title}
        subtitle={payrollCalculation.subtitle}
        illustration={payrollCalculation.illustration}
        name={payrollCalculation.name}
        url={payrollCalculation.url}
        isExternal={true}
      />

      <FAQList title={"Payroll With Bento FAQs"} questions={questions} />
      <MoreForYouComponent moreForYou={moreForYou1} />
      <CTABusiness />
    </Fragment>
  )
}

export default PayrollBento

